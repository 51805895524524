.pdf-page-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100%;
  padding: 0 0 50px 0;
  align-items: center;
  overflow-y: scroll;
}
.pdf-wrapper {
  width: 80%;
  display: flex;
  justify-content: center;
}
.react-pdf__Document {
  display: flex;
  justify-content: center;
  padding: 50px 50px 0 50px;
}
.react-pdf__Page__textContent {
  border: 1px solid darkgrey;
  border-radius: 5px;
}
.pdf-page-button-wrapper p {
  text-align: center;
}
.pdf-page-button-wrapper button {
  width: 100px;
  margin: 0 8px;
}
.pdf-page-kvkk-text-accept-button {
  margin: 12px 0;
}

@media only screen and (max-width: 1445px) {
  .pdf-wrapper {
    display: block;
  }
}
@media only screen and (max-width: 415px) {
  .pdf-page-container {
    max-width: 100% !important;
  }
  .pdf-wrapper {
    max-width: 80% !important;
  }
}
