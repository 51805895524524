@import '../variables';

$_types: (
  default: (
    bg: rgba(#ebebeb, 0.3),
    color: inherit,
    border-color: rgba(#ebebeb, 0.3)
  ),
  accent: (
    bg: accent-palette(500),
    color: accent-contrast(500),
    border-color: accent-palette(500)
  ),
  success: (
    bg: $success-color,
    color: $success-contrast,
    border-color: $success-color
  ),
  info: (
    bg: $info-color,
    color: $info-contrast,
    border-color: $info-color
  ),
  warning: (
    bg: $warning-color,
    color: $warning-contrast,
    border-color: $warning-color
  ),
  error: (
    bg: $error-color,
    color: $error-contrast,
    border-color: $error-color
  )
);

$_shape: $shape;

.ant-alert {
  border-radius: $_shape;
  border-width: 0;
  width: 100%;

  &.ant-alert-no-icon {
    padding: ($module-rem * 1.5) ($module-rem * 2);
  }

  @each $_type, $_color in $_types {
    &.ant-alert-#{$_type} {
      background: map-get($_color, bg);
      border-color: map-get($_color, border-color);
      color: map-get($_color, color);

      &:hover {
        .ant-alert-close-icon {
          opacity: 0.8;
        }
      }

      .ant-alert-message,
      .ant-alert-icon {
        color: map-get($_color, color);
      }
      .ant-icon-close {
        color: map-get($_color, color) !important;
      }
    }
  }

  .ant-alert-close-icon {
    top: $module-rem * 1.5;
    font-size: 14px;
    line-height: 21px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}
