.consent_choice_container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 0px;
    margin: 0px;
}

.consent_choice_row {
    width: 100%;
    box-sizing: border-box;
  }

.consent_choice_label {
    text-align: start;
    width: 120px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.consent_choice_form_item {
  display: inline-block;
  width: 80%;
}

@media screen and (min-width: 768px) {
  .consent_choice_row {
    width: 50% !important;
  }

  .consent_choice_form_item {
    width: 320px;
  }
}

.divider {
  margin: 0 0 16px 0;
}