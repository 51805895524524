.info_card_wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: 16px 0;
  margin-bottom: 64px;
}
.info_card {
  max-width: 320px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title span {
  width: 35%;
}
.title div {
  width: 65%;
}
.title div p {
  width: 100%;
  border: 0;
  border-bottom: 8px dotted #ccc;
  margin: 8px 0;
}
.news_title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
}
.news_seperator {
  max-width: 60%;
}
.carousel_container {
  height: 320px;
}
.carousel_card_container {
  width: 320px;
  height: 320px;
  padding-right: 8px;
  padding-left: 8px;
}
.carousel_card {
  width: 320px;
  height: 320px;
  border-radius: 8px;
}
.carousel_interior_container {
  width: 320px;
  height: 320px;
  position: relative;
}
.carousel_card_image {
  width: 320px;
  height: 320px;
  border-radius: 8px !important;
}
.carousel_card_title {
  width: 320px;
  height: 60px;
  position: absolute;
  bottom: -20px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 8px !important;
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  background: rgba(7, 7, 7, 0.5);
  color: white;
  font-size: 14px;
}
@media only screen and (max-width: 415px) {
  .title span {
    font-size: 18px;
    width: 100%;
  }
  .title div {
    width: 0%;
  }
  .title div p {
    width: 100%;
    border: 0;
    margin: 0px 0;
  }
}
