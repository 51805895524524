.slider_container {
  overflow: hidden;
}
.slider_items_wrapper {
  width: calc(100vw / 100 * 70 * 2);
  display: flex;
  position: relative;
  transition: ease-in-out 1s;
}
.items {
  display: flex;
  position: relative;
}
.item {
  width: calc(100vw / 100 * 70);
}
.form {
  max-width: 600px;
}
