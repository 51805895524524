/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.medi-onam-logo * {
  width: 100% !important;
  padding: 20px;
}
.customize_date_picker {
  background-color: #ebebeb !important;
  display: flex !important;
  justify-content: center;
  width: 100%;
  border: none !important;
  outline: none !important;
  border-radius: 18px;
  line-height: 1.42857 !important;
  height: 40px !important;
  padding: 0 !important;
}

.customize_date_picker * {
  border: 0px solid transparent !important;
}

.customize_country_gsm {
  background-color: #ebebeb !important;
  display: flex !important;
  width: 100% !important;
  border: none !important;
  outline: none !important;
  border-radius: 18px;
  line-height: 1.42857 !important;
  height: 40px !important;
  padding: 0 !important;
  border: 0px solid transparent !important;
}