.gsm {
background-color: #EBEBEB;
border-radius: 20px;
display:flex;
border:#EBEBEB 1px solid;
box-shadow: none;
width: 100%;
height: 40px;
align-items: center;
padding: 0 12px;
}
.gsm[data-active=true] {
    border : 1px solid red;
}
