@import '../../../assets/sass/variables';

@keyframes loaderAnimation {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.footer {
  border-top: 1px solid #e9e9e9;
  bottom: 0;
  display: block;
  height: $footer-height;
  left: 0;
  position: absolute;
  right: 0;
  .footer-wrap {
    background: inherit;
    height: 100%;
    padding: $module-rem ($module-rem * 2);
    position: relative;
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: none !important;
    .footer-skeleton {
      background: inherit;
      height: 100%;
      left: 0;
      padding: $module-rem ($module-rem * 2);
      position: absolute;
      right: 0;
      top: 0;
      transition: 0.2s opacity 0s $animation, 0s visibility 0s $animation;
      z-index: 999;

      .row {
        height: inherit;
      }
      .bg {
        background: #ebebeb;
      }
      .bg-1 {
        background: #f5f5f5;
      }
      .animated-bg {
        overflow: hidden;
        position: relative;
      }
      .animated-bg:before {
        animation-duration: 1.25s;
        animation-fill-mode: forwards;
        animation-iteration-count: infinite;
        animation-name: loaderAnimation;
        animation-timing-function: linear;
        background: transparent;
        background: linear-gradient(
          to right,
          transparent 10%,
          rgba(#fff, 0.5) 18%,
          transparent 33%
        );
        background-size: 800px 100%;
        content: '';
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }

      .page-breadcrumbs {
        margin: 0 - calc($module-rem / 2);

        .item {
          border-radius: 3px;
          display: block;
          height: $module-rem;
          margin: 0 calc($module-rem / 2);
          width: 80px;
        }
      }

      .info {
        align-items: center;
        display: flex;
        margin: 0 - calc($module-rem / 2);

        .version {
          border-radius: 3px;
          display: block;
          height: $module-rem;
          margin: 0 calc($module-rem / 2);
          width: 80px;
        }
        .settings {
          background: accent-palette(500);
          border-radius: 500px;
          display: block;
          height: $module-rem * 2;
          margin: 0 calc($module-rem / 2);
          width: $module-rem * 2;
        }
      }
    }
  }
  &.boxed {
    .footer-wrap {
      max-width: $boxed-width;
      max-width: var(--boxed-width);
    }
  }
  &.loaded {
    .footer-wrap {
      .footer-skeleton {
        opacity: 0;
        transition: 0.2s opacity 0.2s $animation, 0s visibility 0.4s $animation;
        visibility: hidden;
      }
    }
  }
}

.page-breadcrumbs {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: 0;
  padding: 0;

  .item {
    display: block;
    vertical-align: top;
    white-space: nowrap;

    .link {
      color: #000;
      opacity: 0.5;
      text-decoration: none;
      transition: opacity 0.2s $animation;

      &:hover {
        opacity: 1;
      }
    }
  }

  .separator {
    margin: 0 (calc($module-rem / 2) -2px);
  }
}
